function Header() {
    return (
        <header>
            <img id="main-logo" src="white_monero.png" alt="logo" />
            <div id="main-title"><strong>OpenMonero</strong> Startpage</div>
            <div id="openmonero-links-div">
                <a href="https://github.com/openmonero">
                    <img id="github-img" src="https://assets.openmonero.com/images/github-mark-white.svg" alt="GitHub Logo"/>
                </a>
                <a href="https://discord.com/invite/EgjWEf6nf3">
                    <img id="discord-img" src="https://assets.openmonero.com/images/discord-mark-white.svg" alt="Discord Logo"/>
                </a>
            </div>
        </header>
    )
}

export default Header;