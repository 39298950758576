import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import './css/main.css';
import './css/header.css';
import './css/leftPanel.css';
import './css/feed.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
