import Feed from './components/feed';
import Header from './components/header';
import LeftPanel from './components/leftPanel';

function App() {
    return (
        <div id="main-div">
           <Header /> 
           <LeftPanel />
           <Feed />
        </div>
    );
}

export default App;