import { useEffect } from "react";

function News() {
    useEffect(() => {
        async function fetchNews() {
            if (document.getElementById("news-main-div").innerHTML !== "") {
                return;
            }
            var theText = fetch("http://api.openmonero.com/news").then(function(response) {
                return response.json(); 
            });
            theText.then(function(result) {
                const items = result.items;
                items.sort((a, b) => ((new Date(Date.parse(b.isoDate)) - new Date(Date.parse(a.isoDate)))));
                let counter = 0
                items.forEach(item => {
                    counter += 1;
                    if (counter = 1) {
                        document.getElementById("news-main-div").innerHTML += "<div class='feed-post-div' style='margin-top: 5px'><a href='" + item.link + "' target='_blank'><h3 class='news-item-title'>" + item.title + "</h3></a><p class='news-item-description'>" + item.contentSnippet + "</p><div class='news-item-last-part'><a href='" + item.sourceLink + "'><p class='news-item-source'>" + item.source + "</p></a><p class='news-item-date'>" + (new Date(Date.parse(item.isoDate))).toLocaleDateString("en-UK") + "</p></div></div>";  
                    } else {
                        document.getElementById("news-main-div").innerHTML += "<div class='feed-post-div'><a href='" + item.link + "' target='_blank'><h3 class='news-item-title'>" + item.title + "</h3></a><p class='news-item-description'>" + item.contentSnippet + "</p><div class='news-item-last-part'><a href='" + item.sourceLink + "'><p class='news-item-source'>" + item.source + "</p></a><p class='news-item-date'>" + (new Date(Date.parse(item.isoDate))).toLocaleDateString("en-UK") + "</p></div></div>";  
                    }
                }) 
            });
        }
        fetchNews();
    }, []);
    return (
        <div id="news-main-div">
        </div>
    )
}

export default News;