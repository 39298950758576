import { useEffect, useState } from "react";

function LeftPanel() {
    const [price, setPrice] = useState("Loading...");
    const [change, setChange] = useState("0");
    const [supply, setSupply] = useState("0");
    const [marketCap, setMarketCap] = useState("0");
    const [marketCapRank, setMarketCapRank] = useState("0");
    const [volume, setVolume] = useState("0");
    const [xmrbtcPrice, setXmrbtcPrice] = useState("0");
    const [xmrusdtPrice, setXmrusdtPrice] = useState("0");
    const [btcusdtPrice, setBtcusdtPrice] = useState("0");
    const [zecusdtPrice, setZecusdtPrice] = useState("0");
    useEffect(() => {
        async function fetchData() {
            var text = await fetch("https://api.coingecko.com/api/v3/coins/monero").then(function(response) {return response.json()})
            setPrice("$" + text.market_data.current_price.usd.toLocaleString("en", { maximumFractionDigits: 2 }));
            if (text.market_data.price_change_percentage_24h.toString().startsWith("-")) {
                setChange(text.market_data.price_change_percentage_24h.toLocaleString("en", { maximumFractionDigits: 2 }));
            } else {
                setChange("+" + text.market_data.price_change_percentage_24h.toLocaleString("en", { maximumFractionDigits: 2 }));
            }
            setSupply(text.market_data.circulating_supply.toLocaleString("en", { maximumFractionDigits: 2 }));
            setMarketCap(text.market_data.market_cap.usd.toLocaleString("en", { maximumFractionDigits: 2 }));
            setMarketCapRank(text.market_cap_rank.toLocaleString("en", { maximumFractionDigits: 2 }));
            setVolume(text.market_data.total_volume.usd.toLocaleString("en", { maximumFractionDigits: 2 }));
            var text = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=monero&vs_currencies=btc%2Cusd").then(function(response) {return response.json()})
            setXmrbtcPrice(text.monero.btc.toLocaleString("en", { maximumFractionDigits: 8 }));
            setXmrusdtPrice(text.monero.usd.toLocaleString("en", { maximumFractionDigits: 2 }));
            var text = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd").then(function(response) {return response.json()})
            setBtcusdtPrice(text.bitcoin.usd.toLocaleString("en", { maximumFractionDigits: 2 }));
            var text = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=zcash&vs_currencies=usd").then(function(response) {return response.json()})
            setZecusdtPrice(text.zcash.usd.toLocaleString("en", { maximumFractionDigits: 2 }));
        }
        try {
            fetchData();
        } catch (e) {
            console.log(e);
        }
    }, []);
    return (
        <div id="left-panel">
            <div id="left-panel-content">
                <div id="left-panel-price">
                    <div id="left-panel-main-price">{price}</div>
                    <div id="left-panel-price-change">{change}%</div>
                </div>
                <h1 id="left-panel-monero-stats-title">Monero Stats</h1>
                <p className="left-panel-stat-title"><strong>Volume :</strong></p>
                <p className="left-panel-stat">${volume}</p>
                <p className="left-panel-stat-title"><strong>Market Cap :</strong></p>
                <p className="left-panel-stat">${marketCap}</p>
                <p className="left-panel-stat-title"><strong>Market Cap Rank :</strong></p>
                <p className="left-panel-stat">{marketCapRank}</p>
                <p className="left-panel-stat-title"><strong>Circulating Supply :</strong></p>
                <p className="left-panel-stat">{supply} XMR</p>
                <h1 id="left-panel-monero-pairs-title">Market Pairs</h1>
                <p className="left-panel-pair"><strong>XMR - USDT </strong><p className="left-panel-price-text">${xmrusdtPrice}</p></p>
                <p className="left-panel-pair"><strong>XMR - BTC </strong><p className="left-panel-price-text">₿{xmrbtcPrice}</p></p>
                <p className="left-panel-pair"><strong>BTC - USDT </strong><p className="left-panel-price-text">${btcusdtPrice}</p></p>
                <p className="left-panel-pair"><strong>ZEC - USDT </strong><p className="left-panel-price-text">${zecusdtPrice}</p></p>
                <p className="left-panel-info">Powered By CoinGecko</p>
                <p className="left-panel-info">Don't forget to set this page as your browser's startpage!</p>
            </div>
            <hr id="left-panel-vl"/>
        </div>
    );
}

export default LeftPanel;