import React, { useState } from "react";
import News from "./news";
import Community from "./community";

function Feed() {
    const [selectedFeed, setSelectedFeed] = useState("news");
    return (
        <div id="feed-main-div">
            <div id="feed-type-buttons-div">
                <button className="feed-type-button" onClick={() => {setSelectedFeed("news")}} id="feed-news-button">News</button>
                <button className="feed-type-button" onClick={() => {setSelectedFeed("community");}} id="feed-community-button">Community (Coming Soon)</button>
            </div>
            <div id="feed-content-div">
                {selectedFeed === "news" ? <News /> : selectedFeed === "community" ? <Community /> : <p>Something went wrong</p>}
            </div>
            <style jsx>{`
                #feed-news-button {
                    background-color: ${selectedFeed === "news" ? "#686868" : "#161616"};
                }
                #feed-community-button {
                    background-color: ${selectedFeed === "community" ? "#686868" : "#161616"};
                }
            `}</style>
        </div>
    );
}

export default Feed;